import React from "react";

const Home = () => {
  return (
    <div className="home-slider-area" id="home">
      <div className="home-slider owl-theme owl-carousel">
        <div className="slider-item slider-bg-one">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="slider-text">
                      <h1>
                        "A mais bela função da humanidade é a de administrar a
                        justiça"
                      </h1>
                      <p> - Voltaire.</p>
                      {/* <a href="#">
                          FREE CONSULTATION
                          <i className="icofont-arrow-right"></i>
                        </a> */}
                    </div>
                  </div>
                  {/* <div className="col-lg-7">
                      <div className="slider-shape">
                        <img className="s-s-one" src={Fundo} alt="" />
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
