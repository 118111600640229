import React from "react";
import Logo from "../../assets/img/logo.png";
// import Home from "../../assets/img/home-two/home-slider/2.png";
import Joice from "../../assets/img/joice.jpg";
import AboutBg from "../../assets/img/home-one/6.png";
import img7 from "../../assets/img/home-one/7.png";
import img8 from "../../assets/img/home-one/8.png";
import Work from "../../assets/img/escritorio.jpg";
import Home from "../home";

const Header = () => {
  return (
    <>
      <div className="navbar-area fixed-top">
        <div className="mobile-nav mean-container">
          <a href="index.html" className="logo">
            <img src={Logo} alt="Logo"  />
          </a>
        </div>

        {/*///////////////////////////////////////// NAVBAR /////////////////////////////////////////*/}
        <div className="main-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <a href="index.html" className="logo">
                <img src={Logo} alt="Logo" style={{width:'180px', height:'110px'}}  />
              </a>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a href="#home" className="nav-link">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#about" className="nav-link ">
                      Quem sou
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href="#practices" className="nav-link ">
                      Areas de atuação
                    </a>
                  </li>
                  

                  {/* <li className="nav-item">
                    <a href="#officer" className="nav-link">
                      O Escritório
                    </a>
                  </li> */}
                </ul>
                <div className="side-nav">
                  <a href="#footer">Contato</a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/*///////////////////////////////////////// HOME /////////////////////////////////////////*/}

      <Home />
      {/*///////////////////////////////////////// SLIDER /////////////////////////////////////////*/}
      <div className="slider-bottom-area">
        <div className="container">
          <div className="row slider-bottom-wrap">
            <div className="col-sm-6 col-lg-4">
              <div className="banner-bottom">
                <ul>
                  <li>
                    <i className="flaticon-leader"></i>
                  </li>
                  <li>
                    <span>Mais de 500</span>
                    <p>Inventários extrajudiciais</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="banner-bottom">
                <ul>
                  <li>
                    <i className="flaticon-auction"></i>
                  </li>
                  <li>
                    <span>Experiencia</span>
                    <p>+ de 10 anos na área</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
              <div className="banner-bottom">
                <ul>
                  <li>
                    <i className="flaticon-support"></i>
                  </li>
                  <li>
                    <span>Suporte</span>
                    <p>Eficiente e humanizado</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*///////////////////////////////////////// HELP /////////////////////////////////////////*/}

      {/* <!-- Help --> */}
      <div className="help-area help-area-three pb-70" id="about">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="help-item help-left">
                <img src={Joice} alt="Help" height="600px" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="help-item">
                <div className="help-right">
                  <h2>
                    A advocacia pra mim é a realização de uma construção de uma
                    vida
                  </h2>
                  <p>
                    Olá, meu nome é Joice Leite! Iniciei minha carreira
                    profissional aos 15 anos na Justiça Estadual da Comarca de
                    Paraibuna, como estagiária do ensino médio. Ali descobri
                    minha paixão pelo direito. Ingressei na faculdade em 2007
                    como bolsista do Prouni e em 2011 conclui minha graduação e
                    fui aprovada IV Exame da OAB. Depois de atuar no
                    extrajudicial intensamente por mais de 10 anos,
                    especialmente com direito sucessório e imobiliário, optei
                    por voltar a advogar. E então, começou a construção deste
                    sonho.
                  </p>
                  <div className="help-inner-left">
                    <ul>
                      <li>
                        <i className="flaticon-checkmark"></i>
                        Ética
                      </li>
                      <li>
                        <i className="flaticon-checkmark"></i>
                        Dedicação
                      </li>
                    </ul>
                  </div>
                  <div className="help-inner-right">
                    <ul>
                      <li>
                        <i className="flaticon-checkmark"></i>
                        Melhor solução
                      </li>
                      <li>
                        <i className="flaticon-checkmark"></i>
                        Advocacia Qualificada
                      </li>
                    </ul>
                  </div>
                  {/* <div className="help-signature">
                   
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="help-shape">
            <img src={AboutBg} alt="Shape" />
          </div>
        </div>
      </div>
      {/* <!-- End Help -->

        <!-- Practice --> */}
      <section className="practice-area pt-100 pb-70" id="practices">
        <div className="container">
          <div className="section-title" style={{ marginBottom: "20px" }}>
            <span>Areas de atuação</span>
            <h2>Especialista em</h2>
          </div>
          <div className="row" style={{justifyContent: "center"}}>
            
              {/* <div className="practice-item">
                <div className="practice-icon">
                  <i className="flaticon-law"></i>
                </div>
                <h3>Direito Civil</h3>
                <p>
                  Atuo na defesa de clientes em desafios envolvendo
                  responsabilidade, obrigações, deveres, apresentando soluções e
                  medidas para assegurar seus direitos.
                </p>
                <a href="practice-details.html">Read More</a>
                <img className="practice-shape-one" src={img7} alt="Shape" />
                <img className="practice-shape-two" src={img8} alt="Shape" />
              </div> */}
            <div className="col-sm-6 col-lg-3">
              <div className="practice-item">
                <div className="practice-icon">
                  <i className="flaticon-family"></i>
                </div>
                <h3>Direito Sucessório</h3>
                <p style={{fontSize:"12px"}}>
                Atuação nas esferas consultiva e contenciosa judicial. Meu objetivo é encontrar as soluções mais eficientes e acessíveis para cada caso em específico. Proponho as medidas cabíveis para preservar as relações e proteger o patrimônio de conflitos intermináveis. Nesse momento de dor pela perda de um ente querido vou te ajudar a não ter motivos para se preocupar com o trâmite da sucessão. A esfera de atuação abrange: inventários judiciais e extrajudiciais, partilhas, testamentos, doação, entre outros.
                </p>

                <img className="practice-shape-one" src={img7} alt="Shape" />
                <img className="practice-shape-two" src={img8} alt="Shape" />
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="practice-item">
                <div className="practice-icon">
                  <i className="flaticon-inheritance"></i>
                </div>
                <h3>Direito Imobiliário </h3>
                <p style={{fontSize:"12px"}}>
                Atuação nas esferas consultiva e contenciosa judicial. Assessoria completa em todos os atos que envolvam transações imobiliárias, iniciando em todas as etapas de due diligence, análise de documentos, elaboração de contratos e lavratura de escrituras públicas, pagamento e emissão dos impostos competentes até o efetivo registro imobiliário. A esfera de atuação abrange: compra e venda, financiamentos imobiliários, doações, dações em pagamento, permutas, incorporações, conferência de bens, entre outros.

                </p>

                <img className="practice-shape-one" src={img7} alt="Shape" />
                <img className="practice-shape-two" src={img8} alt="Shape" />
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="practice-item">
                <div className="practice-icon">
                  <i className="flaticon-mortarboard"></i>
                </div>
                <h3 style={{fontSize:"24px"}}>Planejamento Patrimonial e Sucessório</h3>
                <p style={{fontSize:"12px"}}>
                O planejamento é ferramenta necessária para que você defina o destino de seu patrimônio proporcionando segurança jurídica, redução da carga tributária, inventário e possíveis conflitos familiares. O objetivo principal de um bom planejamento é garantir a preservação patrimonial, reduzir a carga tributária e assegurar a paz entre a família. Como instrumentos do planejamento podemos utilizar testamento, doação, previdência privada, fundos e holdings. 
                </p>
                <img className="practice-shape-one" src={img7} alt="Shape" />
                <img className="practice-shape-two" src={img8} alt="Shape" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Practice -->

        <!-- Expertise --> */}
      {/* <section className="expertise-area expertise-area-two" id="officer">
        <div className="container">
          <div className="row align-items-center">
            <div className="section-title text-left">
              <span>Nosso espaço</span>{" "}
              <h2>Escritório de advocacia dedicado aos nossos clientes</h2>
            </div>
            <div className="col-lg-6" style={{ marginTop: "30px" }}>
              <div className="expertise-item">
                <ul>
                  <li className="wow fadeInUp" data-wow-delay=".3s">
                    <div className="expertise-icon">
                      <i className="flaticon-experience"></i>
                      
                    </div>
                    <h3>Experience</h3>
                    <p>
                      Team of Lyzo are exceptionally skilled, realize their
                      client’s condition, help them to achieve their motive.
                    </p>
                  </li>
                  <li className="wow fadeInUp" data-wow-delay=".4s">
                    <div className="expertise-icon">
                      <i className="flaticon-lawyer"></i>
                   
                    </div>
                    <h3>Skilled Attorney</h3>
                    <p>
                      Our attorneys are creative and knowledgeable and support
                      clients by finding numerous solutions to win.
                    </p>
                  </li>
                  <li className="wow fadeInUp" data-wow-delay=".5s">
                    <div className="expertise-icon">
                      <i className="flaticon-balance"></i>
                      
                    </div>
                    <h3>Legal Proces</h3>
                    <p>
                      We manage all valid papers and paperwork and continue to
                      enter our path lawfully.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="expertise-image">
              <img src={Work} alt="Expertise" height="560px" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/*  <!-- Footer --> */}
      <footer id="footer" style={{paddingTop:"15px"}}>
        <div className="container">
          <div className="row" >
            <div className="col-sm-6 col-lg-3">
              <div className="footer-item">
                <div className="footer-logo">
                  <a href="#home">
                    <img src={Logo} alt="Logo"style={{ width:"180px", height:"120px"}} />
                  </a>
                  <p>
                    Estou sempre disponível para lhe prestar o melhor e mais
                    eficiente atendimento. Ainda assim, se você quiser saber
                    mais pode se conectar através das redes sociais.
                  </p>
                  <ul>
                    <li>
                      <a
                        href="https://www.instagram.com/sucessoeseimobiliario/?hl=pt-br"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="icofont-instagram"></i>
                      </a>
                    </li>

                    {/* <li>
                      <a
                        href="https://www.linkedin.com/in/joice-leite-39494b62/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="icofont-linkedin"></i>
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="https://api.whatsapp.com/send?phone=+5512997681554&text=Ol%C3%A1!%20Meu%20nome%20%C3%A9%20Joice%20Leite%20e%20estou%20aqui%20para%20descomplicar%20o%20direito!%20No%20que%20posso%20ajudar?"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="icofont-whatsapp"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-2">
              <div className="footer-item">
                <div className="footer-service">
                  <h3> Links</h3>
                  <ul>
                    <li>
                      <a href="#home">
                        <i className="icofont-simple-right"></i>
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="#about">
                        <i className="icofont-simple-right"></i>
                        Sobre
                      </a>
                    </li>

                    <li>
                      <a href="#practices">
                        <i className="icofont-simple-right"></i>
                        Areas de atuação
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="footer-item">
                <div className="footer-find">
                  <h3>Contatos</h3>
                  <ul>
                    <li>
                      <i className="icofont-ui-call"></i>
                      <a href="tel:+55012997681554">(12)99768-1554 </a>
                    </li>
                    <li>
                      <i className="icofont-at"></i>
                      <a href="mailto:joice@joiceleite.com.br">
                        {" "}
                        joice@joiceleite.com.br{" "}
                      </a>
                    </li>
                    <li>
                      <i className="icofont-location-pin"></i>
                      Rua Juiz Davi Barrili, 304, sala 405 - São José dos
                      Campos-SP
                    </li>
                    <li>
                      <i className="icofont-instagram"></i>
                      <a href="https://www.instagram.com/sucessoeseimobiliario/?hl=pt-br">
                        {" "}
                        Acompanhe nossas novidades{" "}
                      </a>
                    </li>
                    <li>
                      <i className="icofont-whatsapp"></i>
                      <a href="https://api.whatsapp.com/send?phone=+5512997681554&text=Ol%C3%A1!%20Meu%20nome%20%C3%A9%20Joice%20Leite%20e%20estou%20aqui%20para%20descomplicar%20o%20direito!%20No%20que%20posso%20ajudar?">
                        {" "}
                        Tire suas dúvidas{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-2">
              <div className="footer-item">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d916.642866875374!2d-45.904652755119734!3d-23.222282855675388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc357ef41dc565%3A0xd2079c5061bf7fe6!2sEdif%C3%ADcio%20Atl%C3%A2ntico%20Empresarial!5e0!3m2!1spt-BR!2sbr!4v1640759191408!5m2!1spt-BR!2sbr"
                  width="300"
                  height="300"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="row">
              <div className="col-sm-7 col-lg-6">
                <div className="copyright-item">
                  <p>Copyright ©2021 Quira Design e Tecnologia</p>
                </div>
              </div>
              <div className="col-sm-5 col-lg-6">
                {/* <div className="copyright-item copyright-right">
                  <a href="terms-conditions.html" target="_blank">
                    Terms & Conditions
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- End Footer --> */}
    </>
  );
};
export default Header;
