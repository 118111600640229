import React from "react";
import ReactDOM from "react-dom";

import "../src/assets/css/animate.css";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/bootstrap.rtl.min.css";
import "../src/assets/css/icofont.min.css";
import "../src/assets/css/magnific-popup.css";
import "../src/assets/css/meanmenu.css";
import "../src/assets/css/nice-select.min.css";
import "../src/assets/css/odometer.min.css";

// import "../src/assets/cssowl.theme.default.min.css";
import "../src/assets/css/responsive.css";
import "../src/assets/css/responsive.css.map";
// import "../src/assets/css/rtl.css";
import "../src/assets/css/style.css";
import "../src/assets/css/style.css.map";
// import "../src/assets/cssowl.carousel.min.css";

import "../src/assets/fonts/flaticon.css";
import "../src/assets/fonts/Flaticon.eot";
import "../src/assets/fonts/Flaticon.svg";
import "../src/assets/fonts/Flaticon.ttf";
import "../src/assets/fonts/Flaticon.woff";
import "../src/assets/fonts/Flaticon.woff2";

import "../src/assets/fonts/icofont.eot";
import "../src/assets/fonts/icofont.svg";
import "../src/assets/fonts/icofont.ttf";
import "../src/assets/fonts/Flaticon.ttf";
import "../src/assets/fonts/icofont.woff";
import "../src/assets/fonts/icofont.woff2";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
